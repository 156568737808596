import {Fragment, useEffect, useState} from "react";
// import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { 
	apiUrl,
	cdnUrl,
	mediaUrl,
} from '../Helpers/General';




function Home(props){


	const [data, 	setData] = useState([]);
	const [slideIdx, setSlideIdx] = useState(0);


	useEffect(() => {

		const pageSlug = (props.lang==='hr') ? 'pocetna' : 'homepage';

		axios.get(`${apiUrl}${props.lang}/api/pages/${pageSlug}`)
                .then(res => res.data)
                .then(data => {
                    setData( data[0]?.translations );
                });

	// eslint-disable-next-line
	},[props])



	return (
		<Fragment>
			
			<div className="page page--home" >

				<div className="slides">
					{data
						.filter( (x,idx) => idx === slideIdx)
						.map( dataItem => {

						const videoMp4 = dataItem.media.find(x => 
							x.title === 'hero' 
							&& x.path.toLowerCase().includes('.mp4')
						) || null;
						
						const videoOgg = dataItem.media.find(x => 
							x.title === 'hero' 
							&& x.path.toLowerCase().includes('.ogg')
						) || null;

						const videoPoster = dataItem.media.find(x => 
							x.title === 'hero' 
							&& (
								x.path.toLowerCase().includes('.jpg') 
								|| x.path.toLowerCase().includes('.jpeg') 
								|| x.path.toLowerCase().includes('.png')
							)  
						) || null;

						return <div 
							key={`slide_${dataItem.slug}`}
							className={`slide`}
						>

							{ dataItem?.title !== undefined && dataItem.title !== '' && 
								<h1>{dataItem.title}</h1>
							}

							{ dataItem?.undertitle !== undefined && dataItem.undertitle !== '' && 
								<h2>{dataItem.undertitle}</h2>
							}

							{ dataItem?.subtitle !== undefined && dataItem.subtitle !== '' && 
								<h3>{dataItem.subtitle}</h3>
							}

							{ dataItem?.description !== undefined && dataItem.description !== '' && 
								<p dangerouslySetInnerHTML={{__html: dataItem.description}} />
							}


							{ ( videoMp4 !== null || videoOgg !== null ) && 
								<video 
									className="hero" 
									autoPlay 
									loop
									muted 
									poster={ (videoPoster !== null) ? `${mediaUrl}${videoPoster?.path}` : '' }
								>
									{ videoMp4 !== null && 
										<source src={`${mediaUrl}${videoMp4.path}`} type="video/mp4" />
									}
									{ videoOgg !== null &&  
										<source src={`${mediaUrl}${videoOgg.path}`} type="video/ogg" />
									}
								</video>
							}

							{ videoPoster !== null && (videoMp4 == null && videoOgg === null ) && 
								<img 
									className="hero" 
									src={`${cdnUrl}${videoPoster.path}`} 
									alt="hero" 
								/>
							}



						</div>
					})}
				</div>

				<div className="pagination">
					{data
						.map( (itm, itmIdx) => 
							<button
								key={`slide_btn_${itmIdx}`}
								className={`pagination-item ${(itmIdx === slideIdx) ? 'pagination-item--active' : 'pagination-item--inactive'}`}
								onClick={() => setSlideIdx(itmIdx)}
							>
								{itm.title}.{itm.subtitle}
							</button>
					)}
				</div>

			</div>
		
		</Fragment>
	);

}

export default Home;